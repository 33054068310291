import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql, useStaticQuery } from 'gatsby'
import HeaderSEO from '../components/partials/HeaderSEO'

import Layout from '../components/Layout'
import { StaticImage } from 'gatsby-plugin-image'

const upgradeOK = () => {
  const data = useStaticQuery(graphql`
    query FormUpgradeSubmittedSEO {
      allContentfulPages(
        filter: {
          pageReference: { pageIdentifier: { eq: "Form_UpgradeSubmitted" } }
        }
      ) {
        edges {
          node {
            title {
              title
            }
            keywords {
              keywords
            }
            description {
              description
            }
            oGimage {
              fluid(quality: 100) {
                src
              }
            }
          }
        }
      }
    }
  `)

  const pageSEO = data.allContentfulPages.edges[0].node
  return (
    <Layout>
      <HeaderSEO pageSEO={pageSEO} />
      <Helmet>
        <meta name="robots" content="noindex" />
        <meta name="robots" content="nofollow" />
      </Helmet>
      <section className="hero-margin pb-5">
        <div className="container">
          <div className="d-flex flex-wrap mt-3">
            <div className="col-md-8 offset-2 text-center pt-0">
              <StaticImage
                className="img-fluid w-50 mx-auto d-block"
                src="../resources/images/vectors/undraw_active_support_6rwo.svg"
                placeholder="tracedSVG"
                alt="got your request"
              />
              <h1 className="mt-3">We&apos;ve got your request</h1>
              <p className="hero-description-dark">
                Someone from our team will contact you as soon as possible!
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container pb-5">
          <div className="d-flex flex-wrap  mt-5 mb-5">
            <div className="col-md-4">
              <div className="p-5">
                <i
                  className="fa fa-file-text-o single-icon fa-2x"
                  aria-hidden="true"
                ></i>
                <p className="mt-3">
                  Check our latest blog posts and tutorials
                </p>
                <a className="primary-text" href="/blog/" role="button">
                  Blog <i className="red-pointer-right"></i>
                </a>
              </div>
            </div>

            <div className="col-md-4">
              <div className="p-5">
                <i
                  className="fa fa-file-video-o single-icon fa-2x"
                  aria-hidden="true"
                ></i>
                <p className="mt-3">Explore videos and other resources</p>
                <a className="primary-text" href="/videos/" role="button">
                  Videos <i className="red-pointer-right"></i>
                </a>
              </div>
            </div>

            <div className="col-md-4">
              <div className=" p-5">
                <i
                  className="fa fa-users single-icon fa-2x"
                  aria-hidden="true"
                ></i>
                <p className="mt-3">See how our customers use Lenses</p>
                <a className="primary-text" href="/usecases/" role="button">
                  Use cases <i className="red-pointer-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default upgradeOK
